import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import ButtonLink from './ButtonLink'

import { connect } from './redux/blockchain/blockchainActions'

const App = () => {
  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)
  const [searchParams, _] = useSearchParams()

  const telegramId = searchParams.get('telegramId') || ''

  const handleWalletConnect = (e, blockchainNetwork, telegramId) => {
    e.preventDefault()
    dispatch(connect(blockchainNetwork, telegramId))
  }

  return (
    <main className="h-screen flex flex-col justify-center items-center text-center bg-gray-100 p-4">
      <div className="flex items-center justify-center lg:justify-start gap-6">
        <img className="h-24 w-2h-24 sm:h-32 sm:w-32" src="./assets/oxai-logo.png" alt="OxAI" />
        <h1 className="bg-blueGradient bg-text-clip text-5xl md:text-6xl font-black text-center mt-2">OxAI</h1>
      </div>
      <div className="max-w-lg w-full bg-white p-4 sm:p-6 rounded-lg border border-gray-200 flex flex-col gap-6 mt-10">
        <h1 className="text-2xl sm:text-[32px] leading-7 sm:leading-10 text-gray-900 font-bold">Connect your Wallet</h1>
        {blockchain.account ? (
          <a className="text-blue-600 underline" href="https://t.me/OxAIcom_bot">
            Return to OxAI Alpha Hunter Suite
          </a>
        ) : telegramId ? (
          <div className="mt-3">
            <ButtonLink
              title="Ethereum"
              logo="./assets/ethereum.svg"
              handler={(e) => handleWalletConnect(e, 'ethereum', telegramId)}
              fullWidth
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3 sm:gap-y-5 sm:mt-5">
              <ButtonLink
                title="BNB Chain"
                logo="./assets/bsc.svg"
                handler={(e) => handleWalletConnect(e, 'bsc', telegramId)}
              />
              <ButtonLink
                title="Polygon"
                logo="./assets/polygon.svg"
                handler={(e) => handleWalletConnect(e, 'polygon', telegramId)}
              />
              <ButtonLink
                title="Arbitrum"
                logo="./assets/arbitrum.svg"
                handler={(e) => handleWalletConnect(e, 'arbitrum', telegramId)}
              />
              <ButtonLink
                title="Fantom"
                logo="./assets/fantom.svg"
                handler={(e) => handleWalletConnect(e, 'fantom', telegramId)}
              />
              <ButtonLink
                title="Avalanche"
                logo="./assets/avalanche.svg"
                handler={(e) => handleWalletConnect(e, 'avalanche', telegramId)}
              />
              <ButtonLink
                title="Optimism"
                logo="./assets/optimism.svg"
                handler={(e) => handleWalletConnect(e, 'optimism', telegramId)}
              />
            </div>
          </div>
        ) : (
          <div>
            <p>Something went wrong.</p>
            <p>
              Please, open{' '}
              <a className="text-blue-600 underline" href="https://t.me/OxAIcom_bot">
                OxAI Alpha Hunter Suite
              </a>{' '}
              and try again
            </p>
          </div>
        )}
        {blockchain.errorMsg && <p>{blockchain.errorMsg} Try again!</p>}
      </div>
    </main>
  )
}

export default App
