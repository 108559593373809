import React from 'react'

const ButtonLink = ({ title, logo, handler, fullWidth }) => {
  return (
    <button
      onClick={handler}
      className={`${
        fullWidth ? 'w-full sm:justify-center mb-3' : 'sm:justify-start'
      } border border-gray-200 rounded-lg py-1.5 px-3 flex items-center justify-center gap-2 transition-colors duration-200 bg-white hover:bg-gray-100 cursor-pointer`}
    >
      <div className="flex items-center gap-2">
        <img className="h-8 w-8" src={logo} alt="logo" />
        <span className="tex-gray-600">{title}</span>
      </div>
    </button>
  )
}

export default ButtonLink
