// constants
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";

const RPC = {
  ethereum: {
    network: "mainnet",
    rpc: "https://mainnet.infura.io/v3/",
    chainId: 1,
  },
  polygon: {
    network: "matic",
    rpc: "https://matic-mainnet.chainstacklabs.com",
    chainId: 137,
  },
  bsc: {
    network: "binance",
    rpc: "https://bsc-dataseed.binance.org/",
    chainId: 57,
  },
  arbitrum: {
    network: "arbitrum",
    rpc: "https://arbitrum-mainnet.infura.io/v3",
    chainId: 42161,
  },
  optimism: {
    network: "optimism",
    rpc: "https://optimism-mainnet.infura.io",
    chainId: 10,
  },
  fantom: {
    network: "fantom",
    rpc: "https://rpc.ftm.tools/",
    chainId: 250,
  },
  avalanche: {
    network: "avalanche",
    rpc: "https://avalanche-mainnet.infura.io",
    chainId: 43114,
  },
};

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = (blockchainNetwork, telegramId) => {
  return async (dispatch) => {
    dispatch(connectRequest());
    localStorage.clear();

    const providerOptions = {
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "Web 3 Modal Demo",
          infuraId: process.env.REACT_APP_INFURA_ID,
          rpc: {
            [RPC[blockchainNetwork]["chainId"]]: RPC[blockchainNetwork]["rpc"],
          },
        },
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID,
          rpc: {
            [RPC[blockchainNetwork]["chainId"]]: RPC[blockchainNetwork]["rpc"],
          },
        },
      },
    };

    const web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions,
    });
    console.log(RPC[blockchainNetwork]["network"]);
    web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    try {
      const accounts = await provider.request({
        method: "eth_accounts",
      });
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/connectUser`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            telegramId: telegramId,
            wallet: accounts[0],
            blockchain: blockchainNetwork,
          }),
        }
      );

      const { status } = await response.json();
      if (status == "success") {
        dispatch(
          connectSuccess({
            account: accounts[0],
            web3: web3,
          })
        );
      } else {
        dispatch(connectFailed(error));
      }

      // Add listeners start
      provider.on("accountsChanged", (accounts) => {
        dispatch(updateAccount(accounts[0]));
      });
      provider.on("chainChanged", () => {
        window.location.reload();
      });
      // Add listeners end
    } catch (err) {
      dispatch(connectFailed("Something went wrong."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
  };
};
